<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.name" class="account">
          <template slot="prepend">姓名</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="search.mobile" class="mobile">
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model.trim="search.idCard" class="idCard">
          <template slot="prepend">身份证号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model.trim="search.remarks" class="remarks">
          <template slot="prepend">消费项目</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        name: "",
        mobile: "",
        idCard: "",
        remarks: "",
      },
      date: [],
      // 状态选项
      statusOpts: [
        { value: "", label: "请选择" },
        { value: 0, label: "充值成功" },
        { value: 1, label: "充值失败" },
      ],
    };
  },
  methods: {
    searchList() {
      let [startTime = "", endTime = ""] = this.date || [];
      let param = Object.assign({ startTime, endTime }, this.search);
      this.$emit("search", param);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .account.el-input {
    width: 150px;
  }
  .mobile.el-input {
    width: 220px;
  }
  .idCard.el-input {
    width: 290px;
  }
  .remarks.el-input {
    width: 220px;
  }
  .el-select {
    width: 100px;
  }
}
</style>
