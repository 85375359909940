<template>
  <div class="account-table table-wrap">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
      :default-sort = "{prop: 'createdAt', order: 'descending'}"
      @sort-change="sortChange"
    >
      <el-table-column prop="tempId" label="ID" align="center"></el-table-column>
      <el-table-column prop="name" label="姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center" min-width="110"></el-table-column>
      <el-table-column prop="idcard" label="身份证" min-width="165" align="center"></el-table-column>
      <el-table-column prop="username" label="查询商家" align="center"></el-table-column>
      <el-table-column prop="memo" label="消费项目" align="center"></el-table-column>
      <el-table-column label="消费金额(元)" align="center">
        <template slot-scope="scope">{{ (scope.row.amount / 100).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column prop="amount_after" label="可用余额(元)" sortable="custom" align="center"  min-width="110">
        <template slot-scope="scope">{{ (scope.row.amount_after / 100).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column prop="created_at" label="查询时间" sortable align="center" min-width="155"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods:{
      sortChange(val){
          this.$emit('sortChange',val)
      }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
</style>
